import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const SignUpForm = () => {
  const handleGoogleSignup = async () => {
    window.location.href = 'http://localhost:8080/user/auth/signup/google';
  };

  return (
    <div className='justify-center bg-contrastgray h-screen'>
            <Header />
      <div className='text-center mt-[5%] mb-25'>
        <h1 className='text-4xl font-bold text-blue-600 mb-2'>Setup your Account</h1>
        <h1 className='text-xl text-gray-400'>Effortlessly connect and streamline your analytics with our user-friendly interface. Get started in minutes!</h1>
      </div>
      <div className="flex justify-center items-center bg-contrastgray mt-20">
        <div className="flex w-3/4  rounded-lg relative">
          <div className="flex-1 p-10 text-left">
            <h2 className="text-2xl text-gray-200 font-semibold mb-2">Sign Up with an Analytics Provider</h2>
            <h2 className="text-lg mb-6 text-gray-400">Pair your primary analytics provider for a quicker setup</h2>
            <button className="flex items-center justify-start bg-asphalt font-bold shadow-md py-2 px-4 mb-4 rounded" onClick={handleGoogleSignup}>
              <img
                src="https://developers.google.com/static/identity/images/g-logo.png"
                alt="Google"
                className="h-8 mr-2"
              />
              <span className='text-gray-200 font-semibold'>Sign Up with Google</span>
            </button>
          </div>
          <div className="flex-1 p-10 relative text-left">
            <div className="absolute left-0 top-[-20px] bottom-[-20px] border-l border-gray-400"></div>
            <h1 className="text-2xl text-gray-200 font-semibold mb-2">Don’t see your analytics provider?</h1>
            <h1 className="text-lg mb-6 text-gray-400">Sign up anyways, we will message you once your provider is available.</h1>
            <Link to="/signup" className="w-1/2 bg-asphalt text-center text-gray-200 font-semibold shadow-md py-2 mb-4 inline-block rounded">
              Continue to Sign-Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;
