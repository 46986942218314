import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import convertJsonToEmailHtmlTemplate from '../util/converteremail';
import { motion } from 'framer-motion'; // Import Framer Motion

axios.defaults.withCredentials = true;

const CreateForm = () => {
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedPropertyName, setSelectedPropertyName] = useState('Select a property');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://localhost:8080/user/providers/')
            .then(response => {
                setProviders(response.data);
            })
            .catch(error => console.error('Error fetching providers:', error));
    }, []);

    useEffect(() => {
        if (selectedProvider) {
            const providerMap = { 'Google Analytics': 'google' };
            const provider = providerMap[selectedProvider];

            axios.get(`http://localhost:8080/analytics/${provider}/properties`)
                .then(response => {
                    setProperties(response.data.properties || []);
                })
                .catch(error => console.error('Error fetching properties:', error));
        }
    }, [selectedProvider]);

    const animationVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    const handleStart = () => {
        setLoading(true);

        const providerMap = { 'Google Analytics': 'google' };
        const provider = providerMap[selectedProvider];
        const templateName = selectedTemplate;

        axios.get(`http://localhost:8080/analytics/${provider}/preview?property=${selectedProperty}&templateName=${templateName}`)
            .then(response => {
                setLoading(false);

                localStorage.setItem('reportData', JSON.stringify(response.data));
                localStorage.setItem('templateName', templateName);
                localStorage.setItem('property', selectedProperty);
                localStorage.setItem('provider', selectedProvider);

                const htmlContent = generateHtmlForDownload(response.data.report);
                downloadHtmlFile(htmlContent, 'report-template.html');

                navigate('/template-builder', { state: { reportData: response.data } });
            })
            .catch(error => {
                console.error('Error generating report:', error);
                setLoading(false);
            });
    };

    const generateHtmlForDownload = (jsonData) => {
        const htmlTemplate = convertJsonToEmailHtmlTemplate(jsonData);
        const htmlString = ReactDOMServer.renderToStaticMarkup(htmlTemplate);
        return `<!DOCTYPE html><html><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Generated Report</title></head><body>${htmlString}</body></html>`;
    };

    const downloadHtmlFile = (htmlContent, fileName) => {
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    return (
        <div className="flex justify-center items-center h-screen bg-contrastgray relative overflow-hidden subtle-grid">
            {loading ? (
                <Loading />
            ) : step === 0 ? (
                <motion.div
                    className="bg-asphalt p-8 rounded-lg shadow-2xl w-full max-w-2xl mx-auto"
                    initial="hidden"
                    animate="visible"
                    variants={animationVariants}
                >
                    <h2 className="text-2xl text-white font-bold mb-4">Create Report</h2>
                    
                    {/* Step 1: Choose Provider */}
                    <motion.div
                        key="provider"
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        className="mb-4"
                    >
                        <label className="text-white font-semibold">Choose Analytics Provider</label>
                        <select
                            name="provider"
                            value={selectedProvider}
                            onChange={(e) => setSelectedProvider(e.target.value)}
                            className="w-full p-2 bg-contrastgray border text-white border-gray-300 rounded mt-2"
                        >
                            <option value="">Select a provider</option>
                            {providers.map(provider => (
                                <option key={provider.id} value={provider.name}>{provider.name}</option>
                            ))}
                        </select>
                    </motion.div>

                    {/* Step 2: Choose Property */}
                    {selectedProvider && (
                        <motion.div
                            key="property"
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            className="mb-4"
                        >
                            <label className="text-white font-semibold">Choose Property</label>
                            <select
                                name="property"
                                value={selectedProperty}
                                onChange={(e) => {
                                    const propertyId = e.target.value;
                                    setSelectedProperty(propertyId);
                                    setSelectedPropertyName(e.target.options[e.target.selectedIndex].text);
                                }}
                                className="w-full p-2 bg-contrastgray border text-white rounded mt-2"
                            >
                                <option value="">{selectedPropertyName}</option>
                                {properties.map(property => (
                                    <option key={property.id} value={property.id}>{property.name}</option>
                                ))}
                            </select>
                        </motion.div>
                    )}

                    {/* Step 3: Choose Template */}
                    {selectedProperty && (
                        <motion.div
                            key="template"
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            className="mb-4"
                        >
                            <label className="text-white font-semibold">Choose Dashboard Template</label>
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-2">
                                {['Executive', 'Marketing', 'Engineering'].map(template => (
                                    <div
                                        key={template}
                                        className={`p-4 rounded cursor-pointer shadow-lg ${selectedTemplate === template ? 'bg-blue-500 text-white' : 'bg-contrastgray text-gray-800'}`}
                                        onClick={() => setSelectedTemplate(template)}
                                    >
                                        <h3 className="text-lg text-white font-semibold">{template}</h3>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    )}

                    {/* Buttons */}
                    <motion.div
                        key="buttons"
                        initial="hidden"
                        animate="visible"
                        variants={animationVariants}
                        className="flex justify-between mt-6"
                    >
                        <button
                            onClick={() => navigate('/workspace')}
                            className="px-4 py-2 font-bold text-white shadow shadow-md bg-black rounded hover:bg-gray-200 transition duration-300"
                        >
                            Back to Workspace
                        </button>
                        {selectedProvider && selectedProperty && selectedTemplate && (
                            <button
                                onClick={() => setStep(1)}
                                className="px-4 py-2 font-bold text-white shadow shadow-md bg-black rounded hover:bg-gray-200 transition duration-300"
                            >
                                Next
                            </button>
                        )}
                    </motion.div>
                </motion.div>
            ) : (
                <div className="bg-asphalt p-8 rounded-lg shadow-2xl w-full max-w-2xl mx-auto">
                    <h2 className="text-2xl text-white font-bold mb-4">Confirm Your Selections</h2>
                    <div className="bg-contrastgray rounded-lg p-6 mb-6 shadow-md">
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold text-white">Selected Provider</h3>
                            <p className="text-md text-gray-300">{selectedProvider || 'No provider selected'}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold text-white">Selected Property</h3>
                            <p className="text-md text-gray-300">{selectedPropertyName || 'No property selected'}</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold text-white">Selected Template</h3>
                            <p className="text-md text-gray-300">{selectedTemplate || 'No template selected'}</p>
                        </div>
                    </div>

                    <div className="flex justify-between mt-6">
                        <button
                            onClick={() => setStep(0)}
                            className="px-4 py-2 font-bold text-white shadow shadow-md bg-black rounded hover:bg-gray-200 transition duration-300"
                        >
                            Back
                        </button>
                        <button
                            onClick={handleStart}
                            className="px-4 py-2 font-bold text-white shadow shadow-md bg-black rounded hover:bg-gray-200 transition duration-300"
                        >
                            Build Your Template
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateForm;
