import React from 'react';

/**
 * A function that takes in JSON data from the backend and
 * converts it into a structured, mobile-friendly HTML template
 * suitable for emails.
 *
 * @param {Object} jsonData - The JSON data passed from the backend.
 * @returns JSX representation of the mobile-compatible HTML template for email.
 */
function convertJsonToEmailHtmlTemplate(jsonData) {
    if (!jsonData || !jsonData.sections || !Array.isArray(jsonData.sections)) {
        console.error("Invalid JSON data or sections missing.");
        return <p style={{ color: 'red', textAlign: 'center' }}>Invalid data format.</p>;
    }

    const { title, summary, generatedAt, sections } = jsonData;

    // Helper function to format KPI values
    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toLocaleString(); // Adds commas for large numbers
        }
        return value;
    };

    // Helper function to display trend arrows
    const getTrendArrow = (trend) => {
        return trend ? '↑' : '↓';
    };

    return (
        <div style={{ backgroundColor: '#f7f7f7', padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <table width="100%" cellPadding="0" cellSpacing="0" border="0" style={{ maxWidth: '600px', margin: '0 auto', backgroundColor: '#ffffff', border: '1px solid #dddddd', padding: '20px' }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center', paddingBottom: '10px' }}>
                            {/* Report Title */}
                            <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333333' }}>{title}</h1>
                            <p style={{ fontSize: '12px', color: '#999999' }}>Generated on: {new Date(generatedAt).toLocaleString()}</p>
                            <p style={{ fontSize: '14px', color: '#666666', margin: '20px 0' }}>{summary}</p>
                        </td>
                    </tr>

                    {/* Iterate through Sections */}
                    {sections.map((section, sectionIndex) => (
                        <tr key={sectionIndex}>
                            <td style={{ padding: '10px 0' }}>
                                {/* Section Title and Insights */}
                                <h2 style={{ fontSize: '18px', fontWeight: 'bold', color: '#333333', marginBottom: '10px' }}>{section.sectionTitle}</h2>
                                <p style={{ fontSize: '14px', color: '#777777', marginBottom: '10px' }}>{section.insights}</p>

                                {/* Render each KPI in its own row */}
                                <table width="100%" cellPadding="10" cellSpacing="0" border="0">
                                    <tbody>
                                        {Object.keys(section.kpis).map((kpiKey, kpiIndex) => {
                                            const kpi = section.kpis[kpiKey];
                                            return (
                                                <tr key={kpiIndex}>
                                                    <td style={{ width: '100%', textAlign: 'center', border: '1px solid #dddddd', padding: '10px', backgroundColor: '#f9f9f9' }}>
                                                        <h3 style={{ fontSize: '16px', fontWeight: 'bold', color: '#333333' }}>{kpi.title}</h3>
                                                        <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#000000' }}>
                                                            Value: {formatValue(kpi.value)}
                                                            {/* Add trend arrow */}
                                                            {kpi.trend !== null && (
                                                                <span style={{ fontSize: '14px', marginLeft: '5px', color: kpi.trend ? '#28a745' : '#dc3545' }}>
                                                                    {getTrendArrow(kpi.trend)}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default convertJsonToEmailHtmlTemplate;
