import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// get current year

function Footer() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const year = new Date().getFullYear();
        setCurrentYear(year);
    }, []);

  return (
    <footer className="bg-white text-gray-700 py-6 w-full">
      <div className="flex justify-between items-center mx-auto px-4 w-full">
        <div className="text-left">
          <h2 className="text-2xl font-bold">MetriMail</h2>
          <p className="text-sm">Analytics done simple, delivered to your mail</p>
        </div>
        <div className="text-center">
          <ul className="flex space-x-4">
            <li><Link to="/" className="hover:text-gray-500">Home</Link></li>
            <li><Link to="/setup" className="hover:text-gray-500">Get Started</Link></li>
            {/* <li><Link to="/contact" className="hover:text-gray-500">Contact</Link></li> */}
            <li><Link to="/privacy" className="hover:text-gray-500">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className="text-right">
          <p className="text-sm">&copy; 2024 MetriMail. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
