import React, { useState, useRef } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker's default styles
import { FaCalendarAlt } from 'react-icons/fa'; // For calendar icon
import { useNavigate } from 'react-router-dom'; // For navigation
import './Scheduler.css'; // Assuming you add this file for custom CSS

const Scheduler = () => {
    const [step, setStep] = useState(0);
    const [dateTime, setDateTime] = useState(new Date());
    const [frequency, setFrequency] = useState({ repeatEvery: 1, unit: 'Custom' });
    const [selectedDays, setSelectedDays] = useState('Custom');
    const [recipients, setRecipients] = useState(['example@example.com']); // Default email
    const [newEmail, setNewEmail] = useState('');
    const nodeRef = useRef(null);
    const navigate = useNavigate();

    const handleNext = () => setStep(step + 1);
    const handlePrev = () => setStep(step - 1);

    const handleFrequencyChange = (e) => {
        const { name, value } = e.target;
        setFrequency(prev => ({ ...prev, [name]: value }));
    };

    const handleDaysChange = (e) => setSelectedDays(e.target.value);

    const handleAddRecipient = () => {
        if (newEmail && !recipients.includes(newEmail)) {
            setRecipients([...recipients, newEmail]);
            setNewEmail('');
        }
    };

    const handleDeleteRecipient = (email) => {
        setRecipients(recipients.filter((recipient) => recipient !== email));
    };

    const handleGoToPreview = () => {
        const confirmationData = {
            dateTime,
            frequency,
            selectedDays,
            recipients,
        };
        localStorage.setItem('scheduleConfigured', 'true');
        navigate('/preview', { state: { confirmationData } });
    };

    const handleSendNow = () => {
        const currentTime = new Date();
        setDateTime(currentTime); // Set the dateTime to the current time
        console.log('Date and Time set to current time:', currentTime); // Log the current date and time
    };

    return (
        <div className="flex justify-center items-center h-screen bg-contrastgray relative overflow-hidden subtle-grid">
            <TransitionGroup component={null}>
                <Transition key={step} timeout={300} nodeRef={nodeRef}>
                    {(state) => (
                        <div
                            ref={nodeRef}
                            style={{
                                opacity: state === 'entered' ? 1 : 0,
                                transition: 'opacity 300ms ease-in-out',
                                position: 'absolute',
                                inset: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div className="bg-asphalt p-8 rounded-lg shadow-2xl w-full max-w-2xl mx-auto mt-[-10%]">
                                {step === 0 && (
                                    <div className="text-center">
                                        <h2 className="text-2xl text-white font-bold mb-4">Select Date and Time</h2>
                                        <div className="flex items-center justify-center mb-6 space-x-4">
                                            <FaCalendarAlt className="text-white text-2xl" />
                                            <DatePicker
                                                selected={dateTime}
                                                onChange={(date) => setDateTime(date)}
                                                showTimeSelect
                                                dateFormat="Pp"
                                                className="custom-datepicker"
                                                timeIntervals={15}
                                                minDate={new Date()}
                                                minTime={new Date(new Date().setMinutes(new Date().getMinutes() + 20))}
                                                maxTime={new Date().setHours(23, 59)}
                                                timeCaption="Time"
                                                popperPlacement="bottom"
                                            />
                                        </div>
                                        <button
                                            onClick={handleSendNow}
                                            className="mb-6 px-6 py-2 font-bold text-white bg-green-600 rounded hover:bg-green-700 transition duration-300"
                                        >
                                            Send Now
                                        </button>
                                        <div className="flex space-x-12 justify-center mt-4">
                                            <button
                                                onClick={() => navigate('/template-builder')} // Navigate back to /templatebuilder
                                                className="px-6 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={handleNext}
                                                className="px-6 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {step === 1 && (
                                    <div className="text-center">
                                        <h2 className="text-2xl text-white font-bold mb-4">Set Frequency</h2>
                                        <div className="grid grid-cols-1 gap-4 mb-4">
                                            <label className="text-white">Repeat every</label>
                                            <input
                                                type="number"
                                                name="repeatEvery"
                                                value={1} // Limit to 1
                                                readOnly
                                                className="w-full bg-black text-white p-2 rounded mb-4 border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                            />
                                            <select
                                                name="unit"
                                                value={selectedDays}
                                                onChange={handleDaysChange}
                                                className="w-full bg-black text-white p-2 rounded border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                            >
                                                <option value="Do not repeat">Do not repeat</option>
                                                <option value="Every Mon - Fri">Every Mon - Fri</option>
                                                <option value="Everyday">Everyday</option>
                                                <option value="Every Week">Every Week</option>
                                                <option value="Every Month">Every Month</option>
                                                <option value="Custom">Custom</option>
                                            </select>
                                        </div>
                                        <div className="flex space-x-12 justify-center mt-4">
                                            <button
                                                onClick={handlePrev}
                                                className="px-6 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={handleNext}
                                                className="px-6 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {step === 2 && (
                                    <div className="text-center">
                                        <h2 className="text-2xl text-white font-bold mb-4">Add Recipients</h2>
                                        <div className="mb-4">
                                            <div className="flex justify-center items-center mb-4">
                                                <input
                                                    type="email"
                                                    value={newEmail}
                                                    onChange={(e) => setNewEmail(e.target.value)}
                                                    placeholder="Enter email address"
                                                    className="w-full bg-black text-white p-2 rounded border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                                />
                                                <button
                                                    onClick={handleAddRecipient}
                                                    className="ml-4 px-4 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                                >
                                                    Add
                                                </button>
                                            </div>
                                            <div className="flex flex-wrap gap-2 justify-center">
                                                {recipients.map((email, index) => (
                                                    <button
                                                        key={index}
                                                        onClick={() => handleDeleteRecipient(email)}
                                                        className="px-4 py-2 text-white bg-gray-700 rounded hover:bg-red-600 transition duration-300"
                                                    >
                                                        {email} &times;
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex space-x-12 justify-center mt-4">
                                            <button
                                                onClick={handlePrev}
                                                className="px-6 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                            >
                                                Back
                                            </button>
                                            <button
                                                onClick={handleGoToPreview}
                                                className="px-6 py-2 font-bold text-white bg-purple-600 rounded hover:bg-purple-700 transition duration-300"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Transition>
            </TransitionGroup>
        </div>
    );
};

export default Scheduler;
