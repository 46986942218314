import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import convertJsonToHtmlTemplate from '../util/converter';

function TemplateBuilder() {
    const location = useLocation();
    const navigate = useNavigate();
    const [htmlTemplate, setHtmlTemplate] = useState(null);

    const { reportData } = location.state || {};

    useEffect(() => {
        const storedReportData = localStorage.getItem('reportData');
        const reportDataToUse = reportData || (storedReportData && JSON.parse(storedReportData));
    
        if (reportDataToUse && reportDataToUse.report && reportDataToUse.report.sections && Array.isArray(reportDataToUse.report.sections)) {
            const convertedHtml = convertJsonToHtmlTemplate(reportDataToUse.report);
            setHtmlTemplate(convertedHtml);
        } else {
            console.error("Invalid JSON data or sections missing in the reportData.");
        }
    }, [reportData]);
    
    const goToCreate = () => {
        navigate('/create');
    };

    const goToPreview = () => {
        navigate('/preview');
    };

    return (
        <div className="min-h-screen bg-contrastgray">
            <Header />
            <DndProvider backend={HTML5Backend}>
                <div className="converted-template-container">
                    {htmlTemplate || <p className="text-center text-white">No valid report data to display.</p>}
                </div>
                
                <div className="flex justify-center space-x-10">
                    <button 
                        onClick={goToCreate} 
                        className="bg-asphalt border border-white hover:bg-contrastgray text-white font-bold py-2 px-4 mb-10 rounded"
                    >
                        Go to Create
                    </button>
                    <button 
                        onClick={goToPreview} 
                        className="bg-asphalt border border-white hover:bg-contrastgray text-white font-bold py-2 px-4 mb-10 rounded"
                    >
                        Go to Preview
                    </button>
                </div>
            </DndProvider>
        </div>
    );
}

export default TemplateBuilder;
