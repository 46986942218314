import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Setup from './forms/setup';
import TemplateBuilder from './pages/templatebuilder';
import { DashboardProvider } from './components/DashboardContext'; // Import the DashboardProvider
import Preview from './pages/preview'
import SignUpForm from './forms/signup';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import Workspace from './pages/workspace';
import LoginForm from './forms/login';
import CreateForm from './forms/create';
import Scheduler from './components/scheduler'

function App() {
  return (
    <Router>
      <DashboardProvider>  {/* Wrap all routes with the DashboardProvider */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/workspace" element={<Workspace/>} />
          <Route path="/template-builder" element={<TemplateBuilder />} />
          <Route path='/preview' element={<Preview />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/signup' element={<SignUpForm />} />
          <Route path='/login' element={<LoginForm />} />
          <Route path='/create' element={<CreateForm />} />
          <Route path='/schedule' element ={<Scheduler/>} />
        </Routes>
      </DashboardProvider>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
