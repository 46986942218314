import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginForm = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    window.location.href = 'http://localhost:8080/user/auth/signup/google';
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8080/user/auth/login', {
        username: usernameOrEmail.includes('@') ? '' : usernameOrEmail,
        email: usernameOrEmail.includes('@') ? usernameOrEmail : '',
        password: password,
        remember: rememberMe,
      });

      if (response.status === 200) {
        // Redirect to the dashboard or home page after successful login
        navigate('/workspace');
      }
    } catch (err) {
      setError('Invalid username/email or password');
      setTimeout(() => setError(''), 5000); // Clear error after 5 seconds
    }
  };

  return (
    <div className='flex justify-center items-start h-screen bg-white mt-10 subtle-grid'>
      <div className='w-full max-w-sm bg-white p-6 rounded-lg shadow-md'>
        <div className='text-center mb-6'>
          <h1 className='text-2xl text-blue-800 mb-2'>Log in</h1>
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="usernameOrEmail">
              Username or Email
            </label>
            <input
              type="text"
              id="usernameOrEmail"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="mr-2 leading-tight"
            />
            <label className="text-gray-700 text-sm" htmlFor="rememberMe">
              Remember me
            </label>
          </div>
          <div className="mb-2">
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Log in
            </button>
          </div>
          {error && <p className="text-red-500 text-xs italic text-center mb-2">{error}</p>}
          <div className="text-center mb-4">
            <Link to="/forgot-password" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
              Forgot Password?
            </Link>
          </div>
        </form>
        <div className="mb-6">
          <button
            className="w-full flex items-center justify-center border border-black font-bold shadow-md py-2 px-4 rounded"
            onClick={handleGoogleLogin}
          >
            <img
              src="https://developers.google.com/static/identity/images/g-logo.png"
              alt="Google"
              className="h-6 mr-2"
            />
            <span>Log in with Google</span>
          </button>
        </div>
        <div className="text-center">
          <span className="text-gray-400">Don't have an account?</span>
          <Link to="/setup" className="text-blue-500 hover:text-blue-800 font-bold ml-2">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;