import React from 'react';
import { useDrop } from 'react-dnd';
import { useDashboard } from './DashboardContext';

function Dashboard({ editMode }) {
    const { zones, handleDrop, addDataZone, addGraphZone, handleUndo } = useDashboard();
    console.log("Rendering Zones:", zones)
    return (
        <div className="flex flex-col items-center justify-center w-full p-4">
            {editMode && (  // Conditionally render buttons only if `editMode` is true
                <div className="mb-4">
                    <button onClick={addDataZone} className="bg-white border border-black  py-2 px-4 rounded mr-2">
                        Add Data Zone
                    </button>
                    <button onClick={addGraphZone} className="bg-white border border-black  py-2 px-4 rounded mr-2">
                        Add Graph Zone
                    </button>
                    <button onClick={handleUndo} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Undo
                    </button>
                </div>
            )}
            <div className="bg-white shadow-2xl border border-gray-300 p-4" style={{ width: '794px', minHeight: '600px' }}>
                {zones.map((zone, index) => (
                    <div key={index} className="mb-4 last:mb-0">
                        {zone.type === 'data' ? 
                            <DropZone index={index} handleDrop={handleDrop} cell={zone} /> : 
                            <GraphDropZone index={index} handleDrop={handleDrop} cell={zone} />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export function DropZone({ index, handleDrop, cell }) {
    const [, drop] = useDrop({
        accept: 'field',
        drop: (item) => handleDrop(index, item),
    });

    return (
        <div ref={drop} className={`${cell.color} flex justify-center items-center`} style={{ border: '1px dashed grey', minHeight: '150px', flexGrow: 1, padding: '10px' }}>
            {cell.content}
        </div>
    );
}

export function GraphDropZone({ index, handleDrop, cell }) {
    const [, drop] = useDrop({
        accept: 'field',
        drop: (item) => handleDrop(index, item),
    });

    return (
        <div ref={drop} className={`${cell.color} flex flex-col justify-center items-center p-4`} style={{ minHeight: '200px', width: '100%' }}>
            <h2 className="text-lg w-full text-center mb-2">Graph Data Zone</h2>
            {cell.data.length > 0 ? (
                cell.data.map((item, idx) => (
                    <div key={idx} className={`${item.color} text-white p-2 m-2 rounded flex flex-col items-center justify-center`} style={{ width: '100%' }}>
                        <div style={{ backgroundColor: '#fff', color: '#333', padding: '5px 10px', borderRadius: '5px', textAlign: 'center' }}>
                            {item.name}
                        </div>
                    </div>
                ))
            ) : (
                <div className="text-center text-white" style={{ width: '100%' }}>
                    {cell.content} {/* Initially shows "Drop statistic here" */}
                </div>
            )}
        </div>
    );
}

export default Dashboard;
