import React from 'react';
import { Link } from 'react-router-dom';
import { UserCircleIcon, ChartBarIcon, CalendarIcon, UserPlusIcon, Cog8ToothIcon, GlobeAltIcon } from '@heroicons/react/24/solid';

function Menu({ user }) {
    // The user object structure:
    // user.username: User's chosen username
    // user.firstName: User's first name
    // user.lastName: User's last name
    // user.email: User's email address
    // user.authProvider: Indicates the authentication provider (e.g., Google, Unity, Apple)
    // user.verified: Indicates if the user's email is verified (true or false)
    // user.accountType: User's account type (e.g., free, premium)
    console.log(user)
    return (
        <div>
            <div className="flex flex-col items-center justify-center h-screen bg-constrastgray">
               
                <Link to='/create' className=" mt-4 mb-4 text-white border border-white text font-bold py-16 px-36 rounded mb-5 text-lg transition-transform duration-300 hover:scale-105 mt-[-25%]">
                    Create + 
                </Link>
                <div className="space-y-4 flex flex-col">
                    <button className="bg-asphalt font-semibold py-4 px-20 text-white rounded shadow-md flex items-center justify-center transition-transform duration-300 hover:scale-105">
                        <GlobeAltIcon className="h-5 w-5 mr-2 text-white " /> Explore
                    </button>
                    <button className="bg-asphalt font-semibold py-4 px-20 text-white rounded shadow-md  flex items-center justify-center transition-transform duration-300 hover:scale-105">
                        <ChartBarIcon className="h-5 w-5 mr-2 text-white" />  Reports
                    </button>
                    <button className="bg-asphalt font-semibold py-4 px-20  text-white rounded shadow-md  flex items-center justify-center transition-transform duration-300 hover:scale-105">
                        <CalendarIcon className="h-5 w-5 mr-2 text-white" /> Schedule
                    </button>
                    <button className="bg-asphalt font-semibold py-4 px-20 text-white rounded shadow-md  flex items-center justify-center transition-transform duration-300 hover:scale-105">
                        <UserPlusIcon className="h-5 w-5 mr-2 text-white" /> Recipients
                    </button>
                    <button className="bg-asphalt font-semibold py-4 px-20 text-white rounded shadow-md  flex items-center justify-center transition-transform duration-300 hover:scale-105">
                        <Cog8ToothIcon className="h-5 w-5 mr-2 text-white" /> Account
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Menu;