import React, { useEffect, useState } from 'react';
import Menu from '../components/workspace/Menu';
import Header from '../components/Header';

function Workspace() {
  const [user, setUser] = useState(null);

  const fetchUserInfo = async () => {
    try {
        const response = await fetch('http://localhost:8080/user/profile/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (response.status === 401) {
            throw new Error('Unauthorized');
        }

        const data = await response.json();
        setUser(data.data); 
    } catch (error) {
        console.error('Error fetching user information:', error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className="h-screen w-screen bg-contrastgray flex flex-col overflow-hidden">
      <Header user={user} />
      <div className="flex flex-grow justify-center items-center">
        <div className="max-w-sm w-full">
          <Menu user={user} />
        </div>
      </div>
    </div>
  );
}

export default Workspace;
