import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import image from '../images/email.gif';
import appcompatiblity from '../images/app.png';
import business from '../images/business.jpg';
import mailIcon from '../images/mail.webp';
import analyticsIcon from '../images/analytics.webp';
import aiIcon from '../images/AI.webp';


const TypewriterEffect = () => {
  const words = ["executives", "marketing", "engineers", "operations"];
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    let wordIndex = 0;
    const typeInterval = setInterval(() => {
      setTyping(false);
      setTimeout(() => {
        wordIndex = (wordIndex + 1) % words.length;
        setCurrentWord(words[wordIndex]);
        setTyping(true);
      }, 500);
    }, 3500);

    return () => clearInterval(typeInterval);
  }, []);

  return (
    <span className={`typewriter-span ${typing ? 'typing' : ''}`}>{currentWord}</span>
  );
};

function HomeContent({ user }) {

  useEffect(() => {
    const handleScroll = () => {
      const arrow = document.getElementById('arrow-down');
      if (window.scrollY > window.innerHeight / 2) {
        arrow.classList.add('hidden');
      } else {
        arrow.classList.remove('hidden');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToNextSection = () => {
    const header = document.querySelector('header'); // Adjust this selector based on your actual header element
    const headerHeight = header ? header.offsetHeight : 0;
    const sections = document.querySelectorAll('.scroll-section');
    const currentScroll = window.scrollY + window.innerHeight;

    for (let i = 0; i < sections.length; i++) {
      const sectionTop = sections[i].offsetTop - headerHeight;
      if (currentScroll <= sectionTop) {
        window.scrollTo({ top: sectionTop, behavior: 'smooth' });
        break;
      }
    }
  };

  return (
    <div className="scroll-container bg-contrastgray">
      {/* Hero Section */}
      <div className="scroll-section flex justify-center items-center h-screen mt-[-5%]">
        <div className="flex flex-col items-center">
          <div className="rounded-full overflow-hidden w-48 h-48">
            <img src={image} alt="Paper Plane" className="w-full h-full object-cover object-right grayscale brightness-10 contrast-20" />
          </div>
          <br />
          <div className="text-center px-2">
            <h1 className="text-5xl font-bold text-white mb-2">
              Welcome to <span className="font-thin">MetriMail</span>
            </h1>
            <h2 className="text-xl text-gray-400">Analytic dashboards done simple, delivered to your mail</h2>
            <div className="mt-12">
              {user ?
                <Link to='/workspace' className="text-white border border-white font-bold py-3 px-8 rounded-full hover:bg-gray-800 transition duration-300 text-xl">
                  Go to workspace
                  </Link>
                  :
                <Link to="/setup" className="text-white border border-white font-bold py-3 px-8 rounded-full hover:bg-gray-800 transition duration-300 text-xl">
                  Get Started
                </Link>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Arrow Down */}
      <div
        id="arrow-down"
        className="flex justify-center mt-[0%] cursor-pointer animate-bounce-slow"
        onClick={scrollToNextSection}
      >
        <svg
          className="w-8 h-8 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>

      {/* Functionality Showcase */}
      <section className="scroll-section flex justify-center items-center h-screen py-12 bg-contrastgray">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center mt-[-7%]">
          <div className="image-content md:w-1/3 md:mr-2 mt-2 md:mt-0">
            <img src={business} alt="Paper Plane" className="w-full h-auto max-w-sm object-cover object-left" />
          </div>
          <div className="text-content md:w-2/3 mb-4 md:mb-0">
            <h2 className="text-4xl text-white font-semibold mb-4 mt-4">
              Insights on your business for your <TypewriterEffect />
            </h2>
            <h3 className="text-2xl text-white mb-4 mt-4">
              We pride ourselves on being able to build out insights for all members of your team.
            </h3>
            <div className="mt-12">
            {user ?
                <Link to='/workspace' className="text-white border border-white font-bold py-3 px-8 rounded-full hover:bg-gray-800 transition duration-300 text-xl">
                  Get Started
                </Link>
                :
                <Link to="/setup" className="text-white border border-white font-bold py-3 px-8 rounded-full hover:bg-gray-800 transition duration-300 text-xl">
                  Get Started
                </Link>
              }
            </div>
          </div>
        </div>
      </section>

      {/* Functionality Showcase */}
      <section className="scroll-section flex justify-center items-center h-screen py-12 bg-contrastgray">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center mt-[-7%]">
          <div className="text-content md:w-2/3 mb-4 md:mb-0">
            <h2 className="text-4xl text-white font-semibold mb-4 mt-4">
              Universal platform adoption
            </h2>
            <h3 className="text-2xl text-white  mt-4 mb-2">
              We are aiming to have compatibility set up for all analytics platforms
            </h3>  
            <div className="mt-12">
              {user ?
                <Link to='/workspace' className="text-white border border-white font-bold py-3 px-8 rounded-full hover:bg-gray-800 transition duration-300 text-xl">
                  Get Started
                </Link>
                :
                <Link to="/setup" className="text-white border border-white font-bold py-3 px-8 rounded-full hover:bg-gray-800 transition duration-300 text-xl">
                  Get Started
                </Link>
              }
            </div>
          </div>
          <div className="image-content md:w-1/3 md:ml-2 mt-2 md:mt-0">
            <img
              src={appcompatiblity}
              alt="Paper Plane"
              className="w-full h-auto max-w-sm object-cover object-left"
            />
          </div>
        </div>
      </section>





      {/* Pricing Model */}
      <section className="scroll-section flex flex-col justify-center items-center h-screen py-12">
    <div className="container mx-auto px-4 mt-[-7%]">
        <h2 className="text-4xl font-bold text-white text-center mb-8">Pricing</h2>
        <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-1/3 p-4 flex">
                <div className="bg-asphalt rounded-lg shadow-xl p-6 text-center flex flex-col justify-between h-full">
                    <div>
                        <h3 className="text-2xl text-gray-200 font-bold mb-2">MetriMail User</h3>
                        <p className="text-3xl text-gray-400 font-bold mb-4">$19.99/month</p>
                        <p className="text-gray-600 mb-4">Access to all basic features and integrations.</p>
                    </div>
                    {user ? (
                        <Link to='/workspace' className="text-blue-500 font-bold">Sign Up</Link>
                    ) : (
                        <Link to="/setup" className="text-blue-500 font-bold">Sign Up</Link>
                    )}
                </div>
            </div>
            <div className="w-full md:w-1/3 p-4 flex">
                <div className="bg-asphalt rounded-lg shadow-xl p-6 text-center flex flex-col justify-between h-full">
                    <div>
                        <h3 className="text-2xl text-gray-200 font-bold mb-2">Annual Plan</h3>
                        <p className="text-3xl text-gray-400 font-bold mb-4">25% Off</p>
                        <p className="text-gray-600 mb-4">Get a 25% discount when you subscribe for a year.</p>
                    </div>
                    {user ? (
                        <Link to='/workspace' className="text-blue-500 font-bold">Sign Up</Link>
                    ) : (
                        <Link to="/setup" className="text-blue-500 font-bold">Sign Up</Link>
                    )}                </div>
            </div>
            <div className="w-full md:w-1/3 p-4 flex">
                <div className="bg-asphalt rounded-lg shadow-xl p-6 text-center flex flex-col justify-between h-full">
                    <div>
                        <h3 className="text-2xl text-gray-200 font-bold mb-2">Enterprise</h3>
                        <p className="text-3xl text-gray-400 font-bold mb-4">Contact Us</p>
                        <p className="text-gray-600 mb-4">For large businesses needing custom solutions.</p>
                    </div>
                    <Link to="/contact" className="text-blue-500 font-bold">Contact Us</Link>
                </div>
            </div>
        </div>
    </div>
</section>

      {/* Contact Section */}
      <section className="scroll-section flex flex-col justify-center items-center h-screen py-12 bg-contrastgray">
        <div className="container mx-auto px-4  mt-[-7%]">
          <h2 className="text-4xl font-bold text-white text-center mb-2">Contact Us</h2>
          <h3 className="text-xl  text-gray-200 text-center mb-8">Our team is working on making an optimized solution tailored for you</h3>
          <form className="max-w-lg mx-auto">
            <div className="mb-4">
              <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-asphalt leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Your Name" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 bg-asphalt text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Your Email" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor="platform">
                Which platform would you like us to integrate?
              </label>
              <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-asphalt leading-tight focus:outline-none focus:shadow-outline" id="platform" placeholder="Describe the platform"></textarea>
            </div>
            <div className="flex items-center justify-between">
              <button className="bg-asphalt hover:bg-blue-700 text-white border border-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Send
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default HomeContent;
