import {React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeContent from '../components/HomeContent'
import Header from '../components/Header';

function Home() {
    const [user, setUser] = useState(null);  

    const fetchUserInfo = async () => {
        try {
            const response = await fetch('http://localhost:8080/user/profile/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setUser(data.data);
            } else {
                // Handle other non-2xx responses gracefully
                console.error(`Error fetching user profile: ${response.status} - ${response.statusText}`);
                setUser(null); // Optionally clear user state for other errors
            }
        } catch (error) {
            // Handle network or unexpected errors
            console.error('Network or server error while fetching user information:', error);
            setUser(null);
        }
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    return (
        <div className='bg-contrastgray'>
            <Header user={user} setUser={setUser} />
            <HomeContent user={user}/>
        </div>
    );
}

export default Home;