// src/components/RecurrencePopup.jsx
import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker's default styles

const RecurrencePopup = ({ onClose, onSave, startDate }) => {
    const [frequency, setFrequency] = useState("daily"); // daily, weekly, monthly, yearly
    const [repeatEvery, setRepeatEvery] = useState(1);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [monthlyOption, setMonthlyOption] = useState("day"); // "day" or "specificDay"
    const [specificDayOfWeek, setSpecificDayOfWeek] = useState({
        occurrence: "first", // first, second, third, fourth, last
        day: "Monday",       // Monday to Sunday
    });
    const [specificDate, setSpecificDate] = useState({
        month: startDate.getMonth() + 1, // 1-12
        day: startDate.getDate(),        // 1-31
    });
    const [endDate, setEndDate] = useState(null);
    const [summary, setSummary] = useState("");
    const [error, setError] = useState(""); // State for error messages

    const daysOfWeekMap = [
        { label: "S", value: "Sunday", index: 0 },
        { label: "M", value: "Monday", index: 1 },
        { label: "T", value: "Tuesday", index: 2 },
        { label: "W", value: "Wednesday", index: 3 },
        { label: "T", value: "Thursday", index: 4 },
        { label: "F", value: "Friday", index: 5 },
        { label: "S", value: "Saturday", index: 6 },
    ];

    const occurrences = ["first", "second", "third", "fourth", "last"];
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        generateSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequency, repeatEvery, daysOfWeek, monthlyOption, specificDayOfWeek, specificDate, endDate]);

    const handleSave = () => {
        // If there's an error, prevent saving
        if (error) return;

        // Prepare recurrence data matching recurrenceFormat structure
        let recurrenceData = {
            frequency, // 'daily', 'weekly', 'monthly', 'yearly'
            interval: repeatEvery,
            daysOfWeek: (frequency === "weekly") ? daysOfWeek : [],
            dayOfMonth: (frequency === "monthly" && monthlyOption === "day") ? [startDate.getDate()] : [],
            specificDayOfWeek: (frequency === "monthly" && monthlyOption === "specificDay") ? specificDayOfWeek : null,
            specificDate: (frequency === "yearly") ? specificDate : null,
            startDate: startDate.toISOString(),
            endDate: endDate ? new Date(endDate).toISOString() : null,
            summary: summary, // Include summary
        };

        // Call onSave callback with recurrence data
        onSave(recurrenceData);
    };

    const toggleDay = (day) => {
        setDaysOfWeek((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };

    // Helper function to calculate the first occurrence date based on frequency and interval
    const getFirstOccurrence = () => {
        // Clone the start date to avoid mutations
        const start = new Date(startDate);
        if (isNaN(start)) {
            console.error("Invalid Start Date provided:", startDate);
            return null;
        }

        let firstOccurrence = null;

        if (frequency === "daily") {
            firstOccurrence = new Date(start);
            firstOccurrence.setDate(start.getDate() + repeatEvery);
        } else if (frequency === "weekly") {
            if (daysOfWeek.length === 0) {
                console.warn("Weekly Frequency - No days selected.");
                return null; // No days selected
            }

            // Map selected days to their corresponding indices
            const selectedDayIndices = daysOfWeek.map(day => {
                const dayObj = daysOfWeekMap.find(d => d.value === day);
                if (!dayObj) {
                    console.error(`Weekly Frequency - Invalid day selected: ${day}`);
                    return null;
                }
                return dayObj.index;
            }).filter(index => index !== null).sort((a, b) => a - b);

            if (selectedDayIndices.length === 0) {
                console.warn("Weekly Frequency - No valid days after mapping.");
                return null;
            }

            const startDay = start.getDay(); // 0 (Sunday) to 6 (Saturday)

            let daysUntilNext = null;

            // Find the next selected day on or after the start date
            for (let dayIndex of selectedDayIndices) {
                if (dayIndex >= startDay) {
                    daysUntilNext = dayIndex - startDay;
                    break;
                }
            }

            if (daysUntilNext === null) {
                // Next occurrence is in the next week
                daysUntilNext = 7 - startDay + selectedDayIndices[0];
            }

            firstOccurrence = new Date(start);
            firstOccurrence.setDate(start.getDate() + daysUntilNext);

            // Apply the repeat interval (in weeks)
            firstOccurrence.setDate(firstOccurrence.getDate() + (repeatEvery - 1) * 7);

        } else if (frequency === "monthly") {
            if (monthlyOption === "day") {
                firstOccurrence = new Date(start);
                firstOccurrence.setMonth(start.getMonth() + repeatEvery);

                // Handle month overflow
                if (firstOccurrence.getMonth() !== (start.getMonth() + repeatEvery) % 12) {
                    // Set to the last day of the target month
                    firstOccurrence = new Date(start.getFullYear(), start.getMonth() + repeatEvery + 1, 0);
                }

                // Set the date to the desired day
                const desiredDay = start.getDate();
                const lastDayOfTargetMonth = new Date(firstOccurrence.getFullYear(), firstOccurrence.getMonth() + 1, 0).getDate();
                firstOccurrence.setDate(Math.min(desiredDay, lastDayOfTargetMonth));

            } else if (monthlyOption === "specificDay") {
                firstOccurrence = getNthWeekdayOfMonth(start, specificDayOfWeek.occurrence, specificDayOfWeek.day);
                if (!firstOccurrence || firstOccurrence < start) {
                    // Move to the next interval month
                    const nextMonth = new Date(start);
                    nextMonth.setMonth(start.getMonth() + repeatEvery);
                    firstOccurrence = getNthWeekdayOfMonth(nextMonth, specificDayOfWeek.occurrence, specificDayOfWeek.day);
                }
                if (!firstOccurrence) {
                    console.error("Monthly Frequency (Specific Day) - Unable to calculate first occurrence.");
                    return null;
                }
            }
        } else if (frequency === "yearly") {
            if (specificDate) {
                firstOccurrence = new Date(start.getFullYear(), specificDate.month - 1, specificDate.day);
                if (isNaN(firstOccurrence)) {
                    console.error("Yearly Frequency (Specific Date) - Invalid date provided:", specificDate);
                    return null;
                }
                if (firstOccurrence < start) {
                    firstOccurrence.setFullYear(firstOccurrence.getFullYear() + repeatEvery);
                }
            } else {
                console.warn("Yearly Frequency - No specificDate provided.");
                return null;
            }
        } else {
            console.error(`Invalid Frequency Selected: ${frequency}`);
            return null;
        }

        if (!(firstOccurrence instanceof Date) || isNaN(firstOccurrence)) {
            console.error("Invalid First Occurrence Calculated:", firstOccurrence);
            return null;
        }

        return firstOccurrence;
    };

    // Helper function to get the nth weekday of a month
    const getNthWeekdayOfMonth = (date, occurrence, day) => {
        const month = date.getMonth(); // 0-11
        const year = date.getFullYear();
        let occurrenceIndex;

        switch (occurrence) {
            case "first":
                occurrenceIndex = 1;
                break;
            case "second":
                occurrenceIndex = 2;
                break;
            case "third":
                occurrenceIndex = 3;
                break;
            case "fourth":
                occurrenceIndex = 4;
                break;
            case "last":
                occurrenceIndex = -1;
                break;
            default:
                occurrenceIndex = 1;
        }

        const dayObj = daysOfWeekMap.find(d => d.value === day);
        if (!dayObj) {
            console.error(`Invalid day for specificDayOfWeek: ${day}`);
            return null;
        }
        const dayIndex = dayObj.index;

        let dateOfOccurrence;

        if (occurrenceIndex > 0) {
            // Calculate the date of the nth weekday
            dateOfOccurrence = new Date(year, month, 1);
            const firstDayOfMonth = dateOfOccurrence.getDay();
            const diff = (7 + dayIndex - firstDayOfMonth) % 7;
            dateOfOccurrence.setDate(1 + diff + (occurrenceIndex - 1) * 7);

            // Check if the calculated date is within the same month
            if (dateOfOccurrence.getMonth() !== month) {
                console.warn(`The ${occurrence} ${day} does not exist in ${months[month]} ${year}.`);
                return null;
            }
        } else if (occurrenceIndex === -1) { // last
            // Get the last day of the month
            dateOfOccurrence = new Date(year, month + 1, 0); // Last day of the month
            const lastDayOfMonth = dateOfOccurrence.getDay();
            const diff = (7 + lastDayOfMonth - dayIndex) % 7;
            dateOfOccurrence.setDate(dateOfOccurrence.getDate() - diff);
        }

        return dateOfOccurrence;
    };

    const generateSummary = () => {
        let summaryText = "Send ";

        // Frequency and interval
        if (frequency === "daily") {
            summaryText += repeatEvery > 1 ? `every ${repeatEvery} days` : "every day";
        } else if (frequency === "weekly") {
            summaryText += repeatEvery > 1 ? `every ${repeatEvery} weeks` : "every week";
            if (daysOfWeek.length > 0) {
                summaryText += ` on ${daysOfWeek.join(', ')}`;
            }
        } else if (frequency === "monthly") {
            summaryText += repeatEvery > 1 ? `every ${repeatEvery} months` : "every month";
            if (monthlyOption === "day") {
                summaryText += ` on day ${startDate.getDate()}`;
            } else if (monthlyOption === "specificDay" && specificDayOfWeek) {
                summaryText += ` on the ${specificDayOfWeek.occurrence} ${specificDayOfWeek.day}`;
            }
        } else if (frequency === "yearly") {
            summaryText += repeatEvery > 1 ? `every ${repeatEvery} years` : "every year";
            if (specificDate) {
                summaryText += ` on ${months[specificDate.month - 1]} ${specificDate.day}`;
            }
        }

        // Start date
        summaryText += ` starting on ${new Date(startDate).toLocaleDateString()}`;

        // End date with validation
        if (endDate) {
            const firstOccurrence = getFirstOccurrence();
            if (firstOccurrence === null) {
                if (frequency === "weekly" && daysOfWeek.length === 0) {
                    setError("Please select at least one day for weekly recurrence.");
                }
            } else if (endDate < firstOccurrence) {
                setError(`End Date cannot be before the first occurrence on ${firstOccurrence.toLocaleDateString()}.`);
            } else {
                setError("");
                summaryText += ` and ending on ${new Date(endDate).toLocaleDateString()}`;
            }
        } else {
            setError("");
        }

        setSummary(summaryText);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-[#1C1C1E] rounded-lg shadow-lg w-full max-w-lg p-6 relative">
                {/* Close Icon */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-[#E5E5E5] hover:text-gray-400 transition"
                    aria-label="Close"
                >
                    <FaTimes size={20} />
                </button>

                <h2 className="text-2xl font-bold mb-6 text-[#E5E5E5]">Set Recurrence</h2>
                
                {/* Frequency Selection */}
                <div className="mb-4">
                    <label className="block text-[#E5E5E5] font-medium mb-2">Frequency</label>
                    <select
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        className="w-full px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                    </select>
                </div>

                {/* Repeat Every */}
                {(frequency === "daily" || frequency === "weekly" || frequency === "monthly" || frequency === "yearly") && (
                    <div className="mb-4">
                        <label className="block text-[#E5E5E5] font-medium mb-2">Repeat Every</label>
                        <div className="flex space-x-2">
                            <input
                                type="number"
                                min="1"
                                value={repeatEvery}
                                onChange={(e) => setRepeatEvery(Number(e.target.value))}
                                className="w-1/3 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                            />
                            <div className="w-2/3 flex items-center">
                                {frequency === "daily" && <span className="text-lg text-[#E5E5E5]">day{repeatEvery > 1 ? 's' : ''}</span>}
                                {frequency === "weekly" && <span className="text-lg text-[#E5E5E5]">week{repeatEvery > 1 ? 's' : ''}</span>}
                                {frequency === "monthly" && <span className="text-lg text-[#E5E5E5]">month{repeatEvery > 1 ? 's' : ''}</span>}
                                {frequency === "yearly" && <span className="text-lg text-[#E5E5E5]">year{repeatEvery > 1 ? 's' : ''}</span>}
                            </div>
                        </div>
                    </div>
                )}

                {/* Weekly Recurrence: Days Selection */}
                {(frequency === "weekly") && (
                    <div className="mb-4">
                        <label className="block text-[#E5E5E5] font-medium mb-2">Repeat On</label>
                        <div className="flex space-x-2">
                            {daysOfWeekMap.map((day) => (
                                <button
                                    key={day.value}
                                    onClick={() => toggleDay(day.value)}
                                    className={`w-8 h-8 rounded-full border flex items-center justify-center 
                                        ${daysOfWeek.includes(day.value) ? 'bg-purple-600 text-[#E5E5E5]' : 'bg-gray-600 text-gray-300'}
                                        hover:bg-purple-500 hover:text-[#E5E5E5] transition`}
                                >
                                    {day.label}
                                </button>
                            ))}
                        </div>
                        {daysOfWeek.length === 0 && <p className="text-red-500 mt-2">Please select at least one day.</p>}
                    </div>
                )}

                {/* Monthly Recurrence Options */}
                {frequency === "monthly" && (
                    <div className="mb-4">
                        <label className="block text-[#E5E5E5] font-medium mb-2">Repeat On</label>
                        <div className="flex flex-col space-y-4">
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="monthlyOption"
                                    value="day"
                                    checked={monthlyOption === "day"}
                                    onChange={() => setMonthlyOption("day")}
                                    className="form-radio h-4 w-4 text-purple-600 bg-gray-700 border-gray-600"
                                />
                                <span className="ml-2 text-[#E5E5E5]">Same day each month</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="monthlyOption"
                                    value="specificDay"
                                    checked={monthlyOption === "specificDay"}
                                    onChange={() => setMonthlyOption("specificDay")}
                                    className="form-radio h-4 w-4 text-purple-600 bg-gray-700 border-gray-600"
                                />
                                <span className="ml-2 text-[#E5E5E5]">Specific weekday</span>
                            </label>
                        </div>

                        {/* Specific Weekday Options */}
                        {monthlyOption === "specificDay" && (
                            <div className="mt-4 flex space-x-2">
                                <select
                                    value={specificDayOfWeek.occurrence}
                                    onChange={(e) => setSpecificDayOfWeek({ ...specificDayOfWeek, occurrence: e.target.value })}
                                    className="w-1/2 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                >
                                    {occurrences.map((occ, index) => (
                                        <option key={index} value={occ}>{occ.charAt(0).toUpperCase() + occ.slice(1)}</option>
                                    ))}
                                </select>
                                <select
                                    value={specificDayOfWeek.day}
                                    onChange={(e) => setSpecificDayOfWeek({ ...specificDayOfWeek, day: e.target.value })}
                                    className="w-1/2 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                >
                                    {daysOfWeekMap.map((day, index) => (
                                        <option key={index} value={day.value}>{day.value}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                )}

                {/* Yearly Recurrence Options */}
                {frequency === "yearly" && (
                    <div className="mb-4">
                        <label className="block text-[#E5E5E5] font-medium mb-2">Repeat On</label>
                        <div className="flex flex-col space-y-4">
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="yearlyOption"
                                    value="specificDate"
                                    checked={specificDate !== null}
                                    onChange={() => setSpecificDate({ month: startDate.getMonth() + 1, day: startDate.getDate() })}
                                    className="form-radio h-4 w-4 text-purple-600 bg-gray-700 border-gray-600"
                                />
                                <span className="ml-2 text-[#E5E5E5]">On specific date</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="yearlyOption"
                                    value="specificWeekday"
                                    checked={specificDate === null}
                                    onChange={() => setSpecificDate(null)}
                                    className="form-radio h-4 w-4 text-purple-600 bg-gray-700 border-gray-600"
                                />
                                <span className="ml-2 text-[#E5E5E5]">On [Nth] [Day of Week] of [Month]</span>
                            </label>
                        </div>

                        {/* Specific Date Options */}
                        {specificDate !== null && (
                            <div className="mt-4 flex space-x-2">
                                <select
                                    value={specificDate.month}
                                    onChange={(e) => setSpecificDate({ ...specificDate, month: Number(e.target.value) })}
                                    className="w-1/2 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                >
                                    {months.map((month, index) => (
                                        <option key={index} value={index + 1}>{month}</option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    min="1"
                                    max="31"
                                    value={specificDate.day}
                                    onChange={(e) => setSpecificDate({ ...specificDate, day: Number(e.target.value) })}
                                    className="w-1/2 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                    placeholder="Day"
                                />
                            </div>
                        )}

                        {/* Specific Weekday Options */}
                        {frequency === "yearly" && specificDate === null && (
                            <div className="mt-4 flex space-x-2">
                                <select
                                    value={specificDayOfWeek.occurrence}
                                    onChange={(e) => setSpecificDayOfWeek({ ...specificDayOfWeek, occurrence: e.target.value })}
                                    className="w-1/3 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                >
                                    {occurrences.map((occ, index) => (
                                        <option key={index} value={occ}>{occ.charAt(0).toUpperCase() + occ.slice(1)}</option>
                                    ))}
                                </select>
                                <select
                                    value={specificDayOfWeek.day}
                                    onChange={(e) => setSpecificDayOfWeek({ ...specificDayOfWeek, day: e.target.value })}
                                    className="w-1/3 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                >
                                    {daysOfWeekMap.map((day, index) => (
                                        <option key={index} value={day.value}>{day.value}</option>
                                    ))}
                                </select>
                                <select
                                    value={specificDate ? specificDate.month : ''}
                                    onChange={(e) => {
                                        if (!specificDate) return;
                                        setSpecificDate({ ...specificDate, month: Number(e.target.value) });
                                    }}
                                    className="w-1/3 px-3 py-2 bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                >
                                    {months.map((month, index) => (
                                        <option key={index} value={index + 1}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                )}

                {/* End Date Field with DatePicker */}
                {(frequency !== "once") && (
                    <div className="mb-6">
                        <label className="block text-[#E5E5E5] font-medium mb-2">End Date</label>
                        <DatePicker
                            selected={endDate ? new Date(endDate) : null}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="P" // Date-only format
                            className={`w-full px-3 py-2 bg-gray-700 text-[#E5E5E5] border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition ${error ? 'border-red-500' : 'border-gray-600'}`}
                            minDate={new Date(startDate)} // Ensure end date is after start date
                            placeholderText="Select end date"
                            popperPlacement="bottom"
                        />
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                )}

                {/* Summary Text at the Bottom */}
                <div>
                    <p className="text-[#E5E5E5]">{summary}</p>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-600 text-[#E5E5E5] rounded-md hover:bg-gray-500 transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className={`px-4 py-2 bg-purple-600 text-[#E5E5E5] rounded-md hover:bg-purple-500 transition ${error ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!!error}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );

};

export default RecurrencePopup;
