import React, { useState, useRef } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';

function Header({ user, setUser }) {
    // State to control pop-up visibility
    const [isHovered, setIsHovered] = useState(false);
    const timeoutRef = useRef(null);

    // Function to handle mouse enter, clearing any existing timeout
    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setIsHovered(true);
    };

    // Function to handle mouse leave with a delay before hiding the pop-up
    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsHovered(false);
        }, 500); // Delay in milliseconds (adjust as needed)
    };

    const handleLogout = async () => {
        try {
            const response = await fetch('http://localhost:8080/user/auth/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
    
            if (response.status === 401) {
                throw new Error('Unauthorized');
            }

            if (response.ok) {
                setUser(null); 
            }     
        } catch (error) {
            console.error('Error fetching user information:', error);
        }
      };

    return (
        <header className="bg-asphalt py-5 px-6 flex items-center justify-between">
            <div className="flex items-center"> {/* Flex container to align logo and text */}
                <img 
                    src={logo} 
                    alt="MetriMail Logo" 
                    className="h-8 w-auto mr-2 grayscale brightness-120 contrast-20" 
                /> {/* Desaturated logo with grayscale filter */}
                <Link to="/" className="text-2xl font-weight-100 text-white">MetriMail</Link>
            </div>

            {user ? (
                <div 
                    className="relative flex items-center"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <UserCircleIcon className="h-9 w-9 text-gray-400 cursor-pointer mr-2" />
                    {isHovered && (
                        <div 
                            className="absolute top-12 right-0 bg-gray-200 shadow-lg rounded-lg p-4 text-gray-700 w-64"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <p className="mb-2 text-sm">Hi, {user.first_name}!</p>
                            <p className="text-sm"><strong>Email:</strong> {user.email}</p>
                            <button 
                                className="mt-3 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
                                onClick={() => {
                                    handleLogout();
                                }}
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <Link to="/login" className="text-lg font-semibold text-white">Login</Link>
            )}
        </header>
    );
}

export default Header;
