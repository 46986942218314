// src/pages/Preview.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker's default styles
import { FaCalendarAlt, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // For icons
import convertJsonToHtmlTemplate from '../util/converter'; // Import the converter function
import RecipientsInput from '../components/RecipientsInput'; // Import the RecipientsInput component
import RecurrencePopup from '../components/RecurrencePopup';

function Preview() {
    const navigate = useNavigate();

    // Function to get the current time
    const getCurrentTime = () => {
        const now = new Date();
        now.setSeconds(0, 0); // Reset seconds and milliseconds to 0 for a clean display
        return now;
    };

    // Function to get the day of the week
    const getDayOfWeek = (date) => {
        const daysOfWeek = {
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
            7: "Sunday"
        };
        const jsDay = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const mappedDay = jsDay === 0 ? 7 : jsDay; // Map Sunday (0) to 7
        return daysOfWeek[mappedDay];
    };

    const [htmlTemplate, setHtmlTemplate] = useState(null);
    const [recipients, setRecipients] = useState([]); // Placeholder recipient
    const [dateTime, setDateTime] = useState(getCurrentTime()); // Initialize the default to current time
    const [selectedFrequency, setSelectedFrequency] = useState("Only Once"); // Default frequency to "Only Once"
    const [recurrenceFormat, setRecurrenceFormat] = useState({
        frequency: 'once',
        interval: 1, // Default interval
        daysOfWeek: [],
        dayOfMonth: [],
        specificDayOfWeek: null,
        specificDate: null,
        startDate: new Date().toISOString(),
        endDate: null,
    });
    const [addStatus, setAddStatus] = useState(null); // Status for success/error
    const [sendTestEmail, setSendTestEmail] = useState(true);
    const [userInfo, setUserInfo] = useState(null); // State to hold user information
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
    const [summary, setSummary] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [property, setProperty] = useState("");
    const [provider, setProvider] = useState("");

    // Update recipients list
    const updateRecipients = (newRecipients) => {
        setRecipients(newRecipients); // Update the recipient list
        console.log("Updated recipients:", newRecipients);
    };

    useEffect(() => {
        // Simulate loading a report for preview, replace this with actual logic
        const storedReportData = localStorage.getItem('reportData');
        const storedTemplateName = localStorage.getItem('templateName');
        const storedPropertyID = localStorage.getItem('property');
        const storedProvider = localStorage.getItem('provider');
        setTemplateName(storedTemplateName);
        setProperty(storedPropertyID);
        setProvider(storedProvider);
        const reportDataToUse = storedReportData && JSON.parse(storedReportData);

        if (reportDataToUse && reportDataToUse.report && reportDataToUse.report.sections) {
            const convertedHtml = convertJsonToHtmlTemplate(reportDataToUse.report);
            setHtmlTemplate(convertedHtml);
        } else {
            console.error("No valid report data found.");
        }
    }, []);

    useEffect(() => {
        // on init get the user information and email
        axios.get('http://localhost:8080/user/profile/') // Endpoint for fetching providers
            .then(response => {
                setUserInfo(response.data.data);
                setRecipients([response.data.data.email]); // Set initial recipient to user's email
            })
            .catch(error => console.error('Error fetching providers:', error));
    }, []);

    // Generate summary whenever recurrenceFormat changes
    useEffect(() => {
        if (recurrenceFormat.frequency !== "custom") {
            generateSummary();
        }
    }, [recurrenceFormat]);

    // Function to handle frequency changes
    useEffect(() => {
        // Update recurrence format based on selected frequency
        switch (selectedFrequency) {
            case "Everyday":
                setRecurrenceFormat({
                    frequency: 'daily',
                    interval: 1,
                    daysOfWeek: [],
                    dayOfMonth: [],
                    specificDayOfWeek: null,
                    specificDate: null,
                    startDate: new Date().toISOString(),
                    endDate: null,
                });
                break;
            case "Every Mon - Fri":
                setRecurrenceFormat({
                    frequency: 'weekly',
                    interval: 1,
                    daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                    dayOfMonth: [],
                    specificDayOfWeek: null,
                    specificDate: null,
                    startDate: new Date().toISOString(),
                    endDate: null,
                });
                break;
            case "Once a week":
                setRecurrenceFormat({
                    frequency: 'weekly',
                    interval: 1,
                    daysOfWeek: [getDayOfWeek(dateTime)], // Current day of the week
                    dayOfMonth: [],
                    specificDayOfWeek: null,
                    specificDate: null,
                    startDate: new Date().toISOString(),
                    endDate: null,
                });
                break;
            case "Once a month":
                setRecurrenceFormat({
                    frequency: 'monthly',
                    interval: 1,
                    daysOfWeek: [],
                    dayOfMonth: [dateTime.getDate()], // Day of the month
                    specificDayOfWeek: null,
                    specificDate: null,
                    startDate: new Date().toISOString(),
                    endDate: null,
                });
                break;
            case "Only Once":
                setRecurrenceFormat({
                    frequency: 'once', // Only happens once, no interval needed
                    interval: 1,
                    daysOfWeek: [],
                    dayOfMonth: [],
                    specificDayOfWeek: null,
                    specificDate: null,
                    startDate: dateTime.toISOString(),
                    endDate: dateTime.toISOString(), // End date is the same as start for a single occurrence
                });
                break;
            case "Custom":
                setIsCustomModalOpen(true);
                break;
            default:
                break;
        }
    }, [selectedFrequency, dateTime]);

    // Function to handle saving custom recurrence from RecurrencePopup
    const handleSaveRecurrence = (customRecurrenceData) => {
        // Destructure to separate 'summary' from the rest of the data
        const { summary: customSummary, ...recurrenceData } = customRecurrenceData;

        // Set the summary separately
        setSummary(customSummary);

        // Update the state without the 'summary' field
        setRecurrenceFormat({
            ...recurrenceData,
            frequency: "custom", // Ensure frequency is set to 'custom'
        });

        // Log the cleaned recurrence data for debugging
        console.log("Custom Recurrence Data (without summary):", recurrenceData);

        // Update the selected frequency to 'Custom'
        setSelectedFrequency("Custom");

        // Close the RecurrencePopup modal
        setIsCustomModalOpen(false);
    };

    useEffect(() => {
        console.log("Recurrence Format Updated:", recurrenceFormat);
    }, [recurrenceFormat]);

    // Function to handle closing the RecurrencePopup without saving
    const handleCloseRecurrence = (isSaved) => {
        setSelectedFrequency("Only Once");
        setIsCustomModalOpen(false); // Close the modal
    };

    // Function to navigate back to Template Builder
    const goToTemplateBuilder = () => {
        navigate('/template-builder'); // Navigate to Template Builder
    };

    // Function to handle the "Next" button click
    const handleSchedule = () => {
        // Create a JSON object with the selected values

        const providerMap = {
            'Google Analytics': 'google'
        };

        const scheduleData = {
            templateName: templateName,
            time: dateTime.toISOString(), // Format the dateTime as ISO string
            frequency: recurrenceFormat.frequency,
            recipients: recipients,
            sendTestEmail: sendTestEmail,
            recurrenceFormat: recurrenceFormat,
            provider: providerMap[provider] || provider,
            property: property
        };

        // Construct the request URL with provider and property as query parameters
        const url = `http://localhost:8080/schedule`;

        // Log the JSON object to the console
        console.log("Schedule Data:", JSON.stringify(scheduleData, null, 2));

        // Send the POST request
        axios.post(url, scheduleData, {
            withCredentials: true, headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log("Schedule created successfully:", response.data);
                alert('Proceeding to the next step...');
            })
            .catch((error) => {
                console.error("Error creating schedule:", error);
                alert('Failed to create schedule. Please try again.');
            });
    };

    // Function to generate a summary based on recurrenceFormat
    const generateSummary = () => {
        let summaryText = "Send ";

        // Use hardcoded summaries for specific frequency options
        switch (selectedFrequency) {
            case "Only Once":
                summaryText += `only once on ${new Date(dateTime).toLocaleDateString()}`;
                break;
            case "Every Mon - Fri":
                summaryText += `every weekday (Mon - Fri) starting on ${new Date(dateTime).toLocaleDateString()}`;
                break;
            case "Everyday":
                summaryText += `every day starting on ${new Date(dateTime).toLocaleDateString()}`;
                break;
            case "Once a week":
                summaryText += `every week on ${getDayOfWeek(dateTime)} starting on ${new Date(dateTime).toLocaleDateString()}`;
                break;
            case "Once a month":
                summaryText += `once a month on day ${new Date(dateTime).getDate()} starting on ${new Date(dateTime).toLocaleDateString()}`;
                break;
        }

        setSummary(summaryText);
    };

    return (
        <div className="min-h-screen bg-[#1C1C1E] py-10 px-4">
            <DndProvider backend={HTML5Backend}>
                <div className="flex flex-col lg:flex-row justify-between items-start space-y-10 lg:space-y-0 lg:space-x-10 w-full mb-8">
                    {/* Left Column: Scheduling Details */}
                    <div className="w-full lg:w-1/2 flex flex-col items-center justify-center">
                        <h3 className="text-4xl font-bold text-[#E5E5E5] mb-6">Email Setup</h3>
                        <div className="bg-[#2A2A2D] shadow-lg rounded-lg p-6 w-full border-4 border-[#1C1C1E]">
                            <div className="mt-4 text-[#E5E5E5]">
                                {/* Date and Time Selection */}
                                <div className="flex items-center space-x-4 mb-6">
                                    <p className="text-lg font-medium"><strong>Select Date and Time:</strong></p>
                                    <div className="flex items-center">
                                        <FaCalendarAlt className="text-[#E5E5E5] text-2xl mr-2" />
                                        <DatePicker
                                            selected={dateTime}
                                            onChange={(date) => setDateTime(date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="custom-datepicker bg-gray-700 text-[#E5E5E5] rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600 transition"
                                            timeIntervals={1} // Use 1-minute increments
                                            minDate={new Date()} // Restrict selection to today onwards
                                            minTime={getCurrentTime()} // Use the current time as minimum
                                            maxTime={new Date().setHours(23, 59)} // Set max time to end of the day
                                            timeCaption="Time"
                                            popperPlacement="bottom"
                                        />
                                    </div>
                                </div>

                                {/* Frequency Selection */}
                                <div className="flex items-center space-x-4 mb-6">
                                    <p className="text-lg font-medium"><strong>Frequency:</strong></p>
                                    <select
                                        value={selectedFrequency}
                                        onChange={(e) => setSelectedFrequency(e.target.value)}
                                        className="bg-gray-700 text-[#E5E5E5] p-2 rounded border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-600 transition text-lg"
                                    >
                                        <option value="Only Once">Only Once</option>
                                        <option value="Every Mon - Fri">Every Mon - Fri</option>
                                        <option value="Everyday">Everyday</option>
                                        <option value="Once a week">Once a week</option>
                                        <option value="Once a month">Once a month</option>
                                        <option value="Custom">Custom</option>
                                    </select>
                                </div>

                                {/* Recurrence Summary */}
                                {summary && (
                                    <div className="m-4 text-[#E5E5E5]">
                                        {summary}
                                    </div>
                                )}

                                {/* Recipients Input */}
                                {userInfo ? (
                                    <RecipientsInput
                                        initialEmail={userInfo.email} // Safely access email if userInfo exists
                                        recipients={recipients}
                                        onUpdateRecipients={updateRecipients}
                                        className="bg-gray-700 text-[#E5E5E5] border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600 transition text-lg"
                                    />
                                ) : (
                                    <p className="text-[#E5E5E5]">Loading user information...</p>
                                )}

                                {/* Send Test Email Toggle */}
                                <div className="flex items-center mt-6">
                                    <span className="text-[#E5E5E5] font-medium mr-3">Send Test Email Now</span>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={sendTestEmail}
                                            onChange={(e) => setSendTestEmail(e.target.checked)}
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-600 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Buttons: Back to Template Builder and Next */}
                        <div className="flex justify-center mt-10 w-full">
                            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                <button
                                    onClick={goToTemplateBuilder}
                                    className="bg-gray-600 hover:bg-gray-500 text-[#E5E5E5] text-xl font-bold py-2 px-4 rounded transition"
                                >
                                    Back to Template Builder
                                </button>
                                <button
                                    onClick={handleSchedule}
                                    className="bg-purple-600 hover:bg-purple-500 text-[#E5E5E5] text-xl font-bold py-2 px-4 rounded transition"
                                >
                                    Schedule
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Right Column: Template Preview */}
                    <div className="bg-[#2A2A2D] shadow-lg rounded-lg p-6 w-full lg:w-1/2 border-4 border-[#1C1C1E]">
                        <h4 className="text-2xl font-semibold text-[#E5E5E5] mb-4">Template Preview</h4>
                        <div className="max-h-[600px] overflow-y-auto mt-4">
                            {htmlTemplate || <p className="text-red-500 text-center">No valid report data to display.</p>}
                        </div>
                    </div>
                </div>
            </DndProvider>

            {/* Conditionally render RecurrencePopup */}
            {isCustomModalOpen && (
                <RecurrencePopup
                    onClose={handleCloseRecurrence}
                    onSave={handleSaveRecurrence}
                    startDate={dateTime}
                />
            )}
        </div>
    );
}

export default Preview;
