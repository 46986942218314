import React, { useState } from "react";
import { FaPlus, FaMinus } from 'react-icons/fa';

const RecipientsInput = ({ initialEmail, onUpdateRecipients }) => {
    const [recipients, setRecipients] = useState([initialEmail]); // Start with initial email
    const [newEmail, setNewEmail] = useState(''); // Separate input for new entries
    const [errors, setErrors] = useState({});
    const [showError, setShowError] = useState(false); // Track error visibility

    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleAddRecipient = () => {
        if (newEmail && isValidEmail(newEmail) && !recipients.includes(newEmail)) {
            const updatedRecipients = [...recipients, newEmail];
            setRecipients(updatedRecipients); // Add to local list
            onUpdateRecipients(updatedRecipients); // Notify parent component
            setNewEmail(''); // Clear the input field
            setErrors({}); // Clear errors
            setShowError(false); // Hide error message if email is valid
        } else {
            // Show error if the email is invalid or duplicate
            setErrors({ newEmail: true });
            setShowError(true); // Show error message on invalid entry

            // Hide the error message after 5 seconds
            setTimeout(() => setShowError(false), 5000);
        }
    };

    const handleDeleteRecipient = (index) => {
        const updatedRecipients = recipients.filter((_, i) => i !== index);
        setRecipients(updatedRecipients);
        onUpdateRecipients(updatedRecipients);
    };

    const handleEditRecipient = (index, value) => {
        const updatedRecipients = [...recipients];
        updatedRecipients[index] = value;
        setRecipients(updatedRecipients);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: !isValidEmail(value),
        }));
    };

    return (
        <div className="text-white">
            <p className="mb-4 text-lg font-medium"><strong>Recipients:</strong></p>

            {/* Separate input for new email entry */}
            <div className="flex flex-col items-start mb-4">
                <div className="flex items-center w-full">
                    <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleAddRecipient()}
                        placeholder="Add new recipient"
                        className={`bg-black text-white p-2 rounded w-full border-2 ${errors.newEmail ? 'border-red-500' : 'border-gray-600'}`}
                    />
                    <button
                        onClick={handleAddRecipient}
                        className="bg-purple-600 text-white ml-2 p-2 rounded-full hover:bg-purple-700"
                    >
                        <FaPlus />
                    </button>
                </div>

                {/* Error message for newEmail with fade-out effect */}
                {showError && (
                    <p className="text-red-500 text-sm mt-1 transition-opacity duration-1000 ease-out">
                        Please enter a valid, unique email
                    </p>
                )}
            </div>

            {/* Display each recipient in a list with edit/delete options */}
            {recipients.map((email, index) => (
                <div key={index} className="flex items-center mb-2">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => handleEditRecipient(index, e.target.value)}
                        className={`bg-black text-white p-2 rounded w-full ${errors[index] ? 'border-red-500' : 'border-gray-600'}`}
                    />
                    <button
                        onClick={() => handleDeleteRecipient(index)}
                        className="text-white hover:text-red-800 ml-2 p-2"
                    >
                        <FaMinus />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default RecipientsInput;
