import React, { useEffect, useState } from 'react';
import './Loading.css'; // Make sure to include this CSS file

const Loading = ({ messages = ["Loading, please wait...", "Processing your request...", "Hang tight, almost there..."] }) => {
    if (!Array.isArray(messages) || messages.length === 0) {
        messages = ["Loading, please wait..."];
    }

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [messages]);

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-grey text-white">
            <div className="loader mb-4"></div> {/* Spinner */}
            <p className="transition-opacity duration-500">
                {messages[currentMessageIndex]}
            </p>
        </div>
    );
};

export default Loading;
