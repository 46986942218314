import React from 'react';

/**
 * A function that takes in JSON data from the backend and
 * converts it into a structured HTML template.
 *
 * @param {Object} jsonData - The JSON data passed from the backend.
 * @returns JSX representation of the HTML template based on the JSON data.
 */
function convertJsonToHtmlTemplate(jsonData) {
    if (!jsonData || !jsonData.sections || !Array.isArray(jsonData.sections)) {
        console.error("Invalid JSON data or sections missing.");
        return <p className="text-red-500 text-center">Invalid data format.</p>;
    }

    const { title, summary, generatedAt, sections } = jsonData;

    // Helper function to format KPI values
    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toLocaleString(); // Adds commas for large numbers
        }
        return value;
    };

    // Helper function to display trend arrows
    const getTrendArrow = (trend) => {
        return trend ? '↑' : '↓';
    };

    return (
        <div className='flex justify-center items-center min-h-screen'>
            <div className="container">
                {/* Report Title */}
                <h1 className="text-3xl font-bold mt-4 text-center text-white ">{title}</h1>
                <p className="text-center text-gray-500 mb-4">Generated on: {new Date(generatedAt).toLocaleString()}</p>
                <p className="text-lg text-gray-700 text-center mb-6">{summary}</p>

                {/* Iterate through Sections */}
                {sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="section mb-12">
                        {/* Section Title and Insights */}
                        <h2 className="section-title text-2xl font-semibold text-white">{section.sectionTitle}</h2>
                        <p className="section-insights text-gray-400 mb-4">{section.insights}</p>

                        <div className="grid grid-cols-2 gap-6 text-center">
                            {/* Iterate through KPIs (which is an object) */}
                            {Object.keys(section.kpis).map((kpiKey, kpiIndex) => {
                                const kpi = section.kpis[kpiKey];
                                return (
                                    <div key={kpiIndex} className="box bg-asphalt p-6 shadow-lg rounded">
                                        <h3 className="text-xl font-semibold text-gray-400">{kpi.title}</h3>
                                        <div className="flex justify-center items-center mb-2">
                                            <p className="text-lg font-bold text-white">Value: {formatValue(kpi.value)}</p>
                                            {/* Add trend arrow */}
                                            {kpi.trend !== null && (
                                                <span className={`text-lg ml-1 ${kpi.trend ? 'text-green-500' : 'text-red-500'}`}>
                                                    {getTrendArrow(kpi.trend)}
                                                </span>
                                            )}
                                        </div>
                                        <p className="text-sm text-gray-500">Metric: {kpi.metric}</p>
                                        <p className="text-sm text-gray-500">Dimension: {kpi.dimension}</p>
                                        {kpi.comparison && (
                                            <p className="text-sm text-gray-400">Comparison: {kpi.comparison}</p>
                                        )}
                                        {kpi.trend !== null && (
                                            <p className={`text-sm ${kpi.trend ? 'text-green-500' : 'text-red-500'}`}>
                                                {kpi.trend ? 'Trending Up' : 'Trending Down'}
                                            </p>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default convertJsonToHtmlTemplate;
