import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { FaCheck, FaExclamationCircle } from 'react-icons/fa';

const SignUpForm = () => {
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState('next');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    accountType: 'MetriMail User',
    provider: '',
  });
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [emailValid, setEmailValid] = useState(true);
  const [showRequirements, setShowRequirements] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [emailTimeout, setEmailTimeout] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      if (emailTimeout) clearTimeout(emailTimeout);
      setEmailTimeout(setTimeout(() => validateEmail(value), 1000));
    }
  };

  const validateEmail = (email) => {
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    setEmailValid(validEmail);
  };

  const handleNext = () => {
    if (validateForm()) {
      setDirection('next');
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setDirection('prev');
    setStep(step - 1);
  };

  const handleBack = () => {
    navigate('/setup');
  };

  const validateForm = () => {
    const { firstName, lastName, email, password, confirmPassword } = formData;
    if (!firstName || !lastName || !email || !password || !confirmPassword || !emailValid) {
      return false;
    }

    return (
      passwordValidations.length &&
      passwordValidations.uppercase &&
      passwordValidations.lowercase &&
      passwordValidations.number &&
      passwordValidations.specialChar &&
      passwordsMatch
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();      
    console.log(formData);
  };

  useEffect(() => {
    const { password, confirmPassword, email } = formData;
    setPasswordValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
    setPasswordsMatch(password === confirmPassword);
    if (emailTimeout) clearTimeout(emailTimeout);
    setEmailTimeout(setTimeout(() => validateEmail(email), 1000));
  }, [formData.password, formData.confirmPassword]);

  return (
    <div className="flex justify-center items-center h-screen bg-white relative overflow-hidden subtle-grid">
      <TransitionGroup component={null}>
        <CSSTransition key={step} timeout={300} classNames={direction === 'next' ? 'slide-next' : 'slide-prev'}>
          <div className="absolute inset-0 flex justify-center items-center w-full">
            <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-2xl mx-auto mt-[-10%]">
              {step === 0 && (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4">Get Started with MetriMail</h2>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className={`w-full p-2 border ${formData.email === "" || emailValid ? 'border-gray-300' : 'border-red-500'} rounded mb-4`}
                  />
                  {formData.email !== "" && !emailValid && <p className="text-red-500 text-left w-full mb-4">Please enter a valid email address.</p>}
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                    value={formData.password}
                    onFocus={() => setShowRequirements(true)}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                  />
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    required
                    value={formData.confirmPassword}
                    onFocus={() => setShowRequirements(false)}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mb-6"
                  />
                  {showRequirements && (
                    <div className="text-left w-full mb-4">
                      <p className="mb-1">Password must contain:</p>
                      <ul>
                        <li className="flex items-center">
                          {passwordValidations.length ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaExclamationCircle className="text-red-500 mr-2" />
                          )}
                          At least 8 characters
                        </li>
                        <li className="flex items-center">
                          {passwordValidations.uppercase ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaExclamationCircle className="text-red-500 mr-2" />
                          )}
                          An uppercase letter
                        </li>
                        <li className="flex items-center">
                          {passwordValidations.lowercase ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaExclamationCircle className="text-red-500 mr-2" />
                          )}
                          A lowercase letter
                        </li>
                        <li className="flex items-center">
                          {passwordValidations.number ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaExclamationCircle className="text-red-500 mr-2" />
                          )}
                          A number
                        </li>
                        <li className="flex items-center">
                          {passwordValidations.specialChar ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaExclamationCircle className="text-red-500 mr-2" />
                          )}
                          A special character
                        </li>
                      </ul>
                    </div>
                  )}
                  {!showRequirements && !passwordsMatch && (
                    <div className="text-left w-full mb-4">
                      <p className="text-red-500">Passwords do not match</p>
                    </div>
                  )}
                  <div className="flex space-x-12 justify-center mt-4">
                    <button
                      type="button"
                      onClick={handleBack}
                      className="px-4 py-2 font-bold text-black border border-black rounded hover:bg-gray-200 transition duration-300"
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={handleNext}
                      className="px-4 py-2 font-bold text-black border border-black rounded hover:bg-gray-200 transition duration-300"
                      disabled={
                        !emailValid ||
                        !passwordValidations.length ||
                        !passwordValidations.uppercase ||
                        !passwordValidations.lowercase ||
                        !passwordValidations.number ||
                        !passwordValidations.specialChar ||
                        formData.password !== formData.confirmPassword
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {step === 1 && (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4">Which Provider Should We Integrate Next?</h2>
                  <input
                    type="text"
                    name="provider"
                    placeholder="Analytics Provider"
                    required
                    value={formData.provider}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                  />
                  <div className="flex space-x-12 justify-center mt-4">
                    <button
                      type="button"
                      onClick={handlePrev}
                      className="px-4 py-2 font-bold text-black border border-black rounded hover:bg-gray-200 transition duration-300"
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={handleNext}
                      className="px-4 py-2 font-bold text-black border border-black rounded hover:bg-gray-200 transition duration-300"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="text-center">
                  <h2 className="text-2xl font-bold mb-4">Review Your Information</h2>
                  <div className="text-left">
                    <div className="mb-4">
                      <label className="block">First Name:</label>
                      <p className="p-2 border border-gray-300 rounded">{formData.firstName}</p>
                    </div>
                    <div className="mb-4">
                      <label className="block">Last Name:</label>
                      <p className="p-2 border border-gray-300 rounded">{formData.lastName}</p>
                    </div>
                    <div className="mb-4">
                      <label className="block">Email:</label>
                      <p className="p-2 border border-gray-300 rounded">{formData.email}</p>
                    </div>
                    <div className="mb-4">
                      <label className="block">Analytics Provider:</label>
                      <p className="p-2 border border-gray-300 rounded">{formData.provider}</p>
                    </div>
                    <div className="mb-4">
                      <label className="block">Account Type:</label>
                      <p className="p-2 border border-gray-300 rounded">{formData.accountType}</p>
                    </div>
                  </div>
                  <div className="flex space-x-12 justify-center mt-6">
                    <button
                      type="button"
                      onClick={handlePrev}
                      className="px-4 py-2 font-bold text-black border border-black rounded hover:bg-gray-200 transition duration-300"
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="px-4 py-2 font-bold text-black border border-black rounded hover:bg-gray-200 transition duration-300"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default SignUpForm;