// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    border: 8px solid rgba(255, 255, 255, 0.2); /* Light gray background */
    border-top: 8px solid white; /* White spinner foreground */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem; /* Space below spinner */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Fade effect for the text */
.transition-opacity {
    transition: opacity 0.5s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C,EAAE,0BAA0B;IACtE,2BAA2B,EAAE,6BAA6B;IAC1D,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,mBAAmB,EAAE,wBAAwB;AACjD;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA,6BAA6B;AAC7B;IACI,oCAAoC;AACxC","sourcesContent":[".loader {\n    border: 8px solid rgba(255, 255, 255, 0.2); /* Light gray background */\n    border-top: 8px solid white; /* White spinner foreground */\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n    animation: spin 1s linear infinite;\n    margin-bottom: 1rem; /* Space below spinner */\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n/* Fade effect for the text */\n.transition-opacity {\n    transition: opacity 0.5s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
