import React, { createContext, useContext, useState } from 'react';

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
    const [zones, setZones] = useState([]);
    const [history, setHistory] = useState([]);

    const pushToHistory = (currentZones) => {
        setHistory(history => [...history, [...currentZones]]);
    };

    const addDataZone = () => {
        pushToHistory(zones);
        const newZone = { type: 'data', content: "Drop here", color: "bg-white" };
        setZones([...zones, newZone]);
    };

    const addGraphZone = () => {
        pushToHistory(zones);
        const newZone = { type: 'graph', data: [], content: "Drop statistic here", color: "bg-gray-200" };
        setZones([...zones, newZone]);
    };

    const handleDrop = (index, item) => {
        pushToHistory(zones);
        const newZones = zones.map((zone, idx) => {
            if (idx === index) {
                return {...zone, content: item.name, color: item.color};
            }
            return zone;
        });
        setZones(newZones);
    };

    const handleUndo = () => {
        if (history.length > 0) {
            const previousZones = history.pop();
            setZones(previousZones);
            setHistory(history);
        }
    };

    return (
        <DashboardContext.Provider value={{ zones, addDataZone, addGraphZone, handleDrop, handleUndo }}>
            {children}
        </DashboardContext.Provider>
    );
};
